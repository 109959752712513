import React, { Component, PropTypes } from 'react';
import moment from 'moment';
import shuffle from 'lodash/shuffle';
import throttle from 'lodash/throttle';
import { Store } from "pullstate";
import { useStoreState } from "pullstate";
//import articles from './data';

import FlipMove from 'react-flip-move';
import Toggle from './Toggle.jsx';

var data = []
var isLoaded = false
//export var UIStore = new Store({});
const UserContext = React.createContext({
  isLoaded: false,
  articles: []
});

// function GetArticles() {
//   const Articles = useStoreState(UIStore, s => s);
//   return Articles
// }

class ListItem extends Component {
  // constructor(props) {
  //   super(props);
  //   this.state = {
  //     articles: useStoreState(UIStore)
  //   };
  // }
  render() {
    if (!isLoaded) {
      return <div className='center'><img src="loading.gif" class="center"></img></div>;
    } else {
    //const { articles } = Shuffle.this.state;
    const listClass = `list-item card ${this.props.view}`;
    const style = { zIndex: 100 - this.props.index };
    const image = data[this.props.index].image

    //console.log(articles.title)
    var divStyle = ""
    var h3Style = ""
    if (image != null) {
      divStyle = { "background-image": "url('" + data[this.props.index].image + "')",
                        "object-fit": "cover;",
                        "background": "linear-gradient(to bottom, rgba(0, 0, 0, 0), rgba(0, 0, 0, 0.6)," + "url('" + data[this.props.index].image + "')"
                        };
      h3Style = { "text-shadow": "0 1px 0 white;"}
    } else {
      divStyle = style
      h3Style = { "background": "none",
        "text-shadow": "0 1px 0 white;"}
    }

    
    // const divStyle = { "background-image": "linear-gradient(to bottom,rgba(0, 0, 0, 0.0), rgba(0, 0, 0, 0.8)),url('" + data[this.props.index].image + "')",
    //                     "object-fit": "cover;",
    //                     "background": "linear-gradient(to bottom, rgba(0, 0, 0, 0), rgba(0, 0, 0, 0.6)," + "url('" + data[this.props.index].image + "')"
    //                     };
    console.log(this)
    return (
      <UserContext.Provider>
      <li id={this.props.id} className={listClass} style={divStyle}>
      
      {/*<div style={divStyle}>*/}
        <h3 style={h3Style}>{data[this.props.index].title}</h3>
        {/*<background background-image={data[this.props.index].image}></background>*/}
        {/*<h5>{moment(this.props.timestamp).format('MMM Do, YYYY')}</h5>*/}
        <button onClick={this.props.clickHandler}>
          <i className="fa fa-close" />
        </button>
      {/*</div>*/}
      </li>
      </UserContext.Provider>
    );
    }
  }
}

class Shuffle extends Component {
  constructor(props) {
    super(props);
    this.state = {
      removedArticles: [],
      view: 'grid',
      order: 'asc',
      sortingMethod: 'chronological',
      enterLeaveAnimation: 'accordianVertical',
      error: null,
      isLoaded: false,
      items: [],
      //articles
    };

    this.toggleList = this.toggleList.bind(this);
    this.toggleGrid = this.toggleGrid.bind(this);
    this.toggleSort = this.toggleSort.bind(this);
    this.sortRotate = this.sortRotate.bind(this);
    this.sortShuffle = this.sortShuffle.bind(this);
  }

  componentDidMount() {
    fetch('https://bwhtxtwigh.execute-api.us-east-1.amazonaws.com/latest/get')
      .then(res => res.json())
      .then(
        (result) => {
          // User = {
          //   isLoaded: true,
          //   articles: result
          // };
          data = result
          isLoaded = true
          this.setState({
            isLoaded: true,
            articles: result
          });
          //React.createContext(result);
        },
        (error) => {
          this.setState({
            isLoaded: true,
            error
          });
        }
      )
  }

  toggleList() {
    this.setState({
      view: 'list',
      enterLeaveAnimation: 'accordianVertical'
    });
  }

  toggleGrid() {
    this.setState({
      view: 'grid',
      enterLeaveAnimation: 'accordianHorizontal'
    });
  }

  toggleSort() {
    const sortAsc = (a, b) => a.timestamp - b.timestamp;
    const sortDesc = (a, b) => b.timestamp - a.timestamp;

    this.setState({
      order: (this.state.order === 'asc' ? 'desc' : 'asc'),
      sortingMethod: 'chronological',
      articles: this.state.articles.sort(
        this.state.order === 'asc' ? sortDesc : sortAsc
      )
    });
  }

  sortShuffle() {
    this.setState({
      sortingMethod: 'shuffle',
      articles: shuffle(this.state.articles)
    });
  }

  moveArticle(source, dest, id) {
    const sourceArticles = this.state[source].slice();
    let destArticles = this.state[dest].slice();

    if ( !sourceArticles.length ) return;

    // Find the index of the article clicked.
    // If no ID is provided, the index is 0
    const i = id ? sourceArticles.findIndex(article => article.id === id) : 0;

    // If the article is already removed, do nothing.
    if ( i === -1 ) return;

    destArticles = [].concat( sourceArticles.splice(i, 1), destArticles );

    this.setState({
      [source]: sourceArticles,
      [dest]: destArticles,
    });
  }

  sortRotate() {
    const articles = this.state.articles.slice();
    articles.unshift(articles.pop())

    this.setState({
      sortingMethod: 'rotate',
      articles
    });
  }

  renderArticles() {
    const { articles } = this.state;
    return articles.map( (article, i) => {
      console.log(articles)
      return (
        <ListItem
          key={article.id}
          view={this.state.view}
          index={i}
          clickHandler={throttle(() => this.moveArticle('articles', 'removedArticles', article.id), 800)}
          {..."article"}
        />
      );
    });
  }

  render() {
    const { error, isLoaded, articles } = this.state;
    if (error) {
      return <div>Error: {error.message}</div>;
    } else if (!isLoaded) {
      return <div className='center'><img src="loading.gif" class="center"></img></div>;
    } else {
    return (
      <div id="shuffle" className={this.state.view}>
       
        <div>
          <FlipMove
            staggerDurationBy="30"
            duration={500}
            enterAnimation={this.state.enterLeaveAnimation}
            leaveAnimation={this.state.enterLeaveAnimation}
            typeName="ul"
          >
            { this.renderArticles() }
            {/*}
            <footer key="foot">
              <div className="abs-right">
                <Toggle
                  clickHandler={() => (
                    this.moveArticle('removedArticles', 'articles')
                  )}
                  text="Add Item"
                  icon="plus"
                  active={this.state.removedArticles.length > 0}
                />
                <Toggle
                  clickHandler={() => (
                    this.moveArticle('articles', 'removedArticles')
                  )}
                  text="Remove Item"
                  icon="close"
                  active={this.state.articles.length > 0}
                />
              </div>
            </footer>
            */}
          </FlipMove>
        </div>
        {/*
        <header>
          <div className="abs-left">
            <Toggle
              clickHandler={this.toggleList}
              text="List" icon="list"
              active={this.state.view === 'list'}
            />
            <Toggle
              clickHandler={this.toggleGrid}
              text="Grid" icon="th"
              active={this.state.view === 'grid'}
            />
          </div>
          <div className="abs-right">
            <Toggle
              clickHandler={this.toggleSort}
              text={this.state.order === 'asc' ? 'Ascending' : 'Descending'}
              icon={this.state.order === 'asc' ? 'angle-up' : 'angle-down'}
              active={this.state.sortingMethod === 'chronological'}
            />
            <Toggle
              clickHandler={this.sortShuffle}
              text="Shuffle" icon="random"
              active={this.state.sortingMethod === 'shuffle'}
            />
            <Toggle
              clickHandler={this.sortRotate}
              text="Rotate" icon="refresh"
              active={this.state.sortingMethod === 'rotate'}
            />
          </div>
        </header>
        */}
      </div>
    );
  }
  }
};

export default Shuffle